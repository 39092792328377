body {
  margin: 0;
  font-family: "Roboto Flex", "Arial", sans-serif;

  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(
    90deg,
    rgba(198, 221, 245, 1) 6%,
    rgba(205, 224, 242, 1) 32%,
    rgba(220, 233, 246, 1) 59%,
    rgba(231, 238, 242, 1) 96%
  ); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
